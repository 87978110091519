@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    width: 100%;
    height: 100%;
    font-family: Optima, 'Arsenal', sans-serif;
    background-color: #000;
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    min-height: 100%;
}

#video-container {
    max-width: 80%;
    background: #191919;
    margin: 0 auto;
}

.red5pro-media-container {
    height: 100%;
}

#red5pro-subscriber {
    height: 100%;
}

.js-fullscreen {
    min-width: 100%;
    min-height: 100%;
}

video::-webkit-media-controls {
    display :none !important;
  }